<template>
    <el-menu
        active-text-color="#ffd04b"
        background-color="#545c64"
        class="el-menu-vertical-trader"
        default-active="1"
        text-color="#fff"
        @select="handleSelect"

    >
<!--        动态生成导航条-->
            <el-sub-menu v-for="item in barList" :index="String(item.id)" :key="item.id">

<!--                渲染栏目-->
                <template #title>
                    <el-icon :size="15">
                        <component
                            :is=item.icon
                        />
                    </el-icon>
                    <span>{{getLang('items',item.lang,item.item_name)}}</span>
                </template>

<!--                如果有子页面,则渲染子页面-->
                <template v-if="item.list != null">
                    <el-menu-item v-for="list in item.list"
                                  :key="item.id+list.id"
                                  :index="list.router"
                                  :route="list.router"
                    >
                        <el-icon :size="15">
                            <component
                                :is=list.icon
                            />
                        </el-icon>
                        {{getLang('pages',list.page_lang,list.page_name)}}
                    </el-menu-item>
                </template>

            </el-sub-menu>
    </el-menu>
</template>

<script>
export default {
    name: "NavBar",
    data(){
        return {
            barList : {},
            lang:this.$lang.getLang('layout','Navbar'),
        }
    },
    methods:{
        //获取bar列表
        getBarList(){
            let url = 'Api.Bar/getList';
            this.$api.post(url).then((res)=>{
                this.getListData(res.data);
            }).catch(error=>this.$helper.axiosCatch(error));
        },
        /**
         * 解析数据[导航条]
         * @param res php返回的数据
         * @returns {boolean}
         * 成功返回true 解析失败返回false
         */
        getListData(res){
            let data = 'data' in res ? res.data : null;
            if (data == null){
                return false;
            }
            this.barList = data;
            // console.log('getListData',res,data)
            return true;
        },
        //导航条被选择
        handleSelect(index,){
            //向父组件调用事件,说明要跳转新的页面
            this.$emit('newPage',{name:index})
            // console.log('handleSelect',index,indexPath,item,routeResult)
        },
        /**
         * 取出多语言
         * @param type 类型[items/pages]
         * @param field 字段默认名
         * @param defaultLang 默认值
         * @returns {*}
         * 成功返回多语言,失败返回默认值
         */
        getLang(type,field,defaultLang){
            let res = this.lang[type][field] ?? '!' + defaultLang;
            // console.log('getlang',
            //     field,
            //     this.lang[type][field],
            //     defaultLang);
            return res;
        }
    },
    mounted() {
        this.getBarList();
    }
}
</script>

<style scoped>
/*自适应高度*/
.el-menu-vertical-trader {
    height: 91.8vh;
    overflow-y: auto;
    color: #fff;
}
</style>